/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {Header} from '@thryvlabs/maverick'
import {useLocation} from 'react-router'

import {SelectionAdds, ItemContainer} from '../ui/addons-main-components'
import {ThryvValidatedAddons} from './thryv-validated-addons'
import {MonthSemiAnnualSelection} from './month-semiAnnual-selection'

import {ADDONS_TYPES, SEO_BOTH_PLANS} from '../../utils/constants'
import {ADD_ON} from '../../utils/addons-data'
import {AddOnMc} from './add-on-mc'
import {useUserRoles} from '../../utils/use-user-roles'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {SEOSummary} from '../catalog-components/seo-summary-v4'

const yesNoRadioButtons = [
  {
    label: 'No',
    value: 'No',
    default: true,
  },
  {
    label: 'Yes',
    value: 'Yes',
  },
]
const yesRadioButton = [
  {
    label: 'Yes',
    value: 'Yes',
    default: true,
  },
]
const monthYearRadioButton = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Semi-Annually',
    value: 'semiAnnually',
    default: true,
  },
]
const defaultYesRadioButtons = [
  {
    label: 'No',
    value: 'No',
  },
  {
    label: 'Yes',
    value: 'Yes',
    default: true,
  },
]

const MCPlanConfig = {
  planSelection: true,
  radioButtonOptions: yesRadioButton,
}

const ITEMS_CONFIG = {
  'Thryv Leads': {radioButtonOptions: monthYearRadioButton},
  'Thryv CSN': {planSelection: true, radioButtonOptions: yesNoRadioButtons},
  'SEO Non-Guaranteed': {radioButtonOptions: monthYearRadioButton},
  'SEO Money Back Guarantee': {radioButtonOptions: monthYearRadioButton},
  SEO: {planSelection: true, radioButtonOptions: yesNoRadioButtons},
  'SEO PowerBoost': {
    planSelection: true,
    radioButtonOptions: monthYearRadioButton,
  },
  'SEO Keyword': {
    planSelection: true,
    radioButtonOptions: monthYearRadioButton,
  },
  'Enhanced Accessibility': {
    planSelection: true,
    radioButtonOptions: yesNoRadioButtons,
  },
  'Restricted Access': {
    planSelection: true,
    radioButtonOptions: yesNoRadioButtons,
  },
  'Certified Domain Based Gmails': {
    slider: true,
    planSelection: true,
    radioButtonOptions: yesNoRadioButtons,
    maxValue: 10,
    range: 1,
  },
  'Standard Domain Based Gmail': {
    slider: true,
    planSelection: true,
    radioButtonOptions: yesNoRadioButtons,
    maxValue: 10,
    range: 1,
  },
  'Additional Social Boosting': {
    slider: true,
    radioButtonOptions: yesNoRadioButtons,
    maxValue: 2000,
    range: 100,
    symbolTemplate: `\${0}`,
    fixedAmount: 100,
  },
  'Additional Video Views': {
    slider: true,
    radioButtonOptions: yesNoRadioButtons,
    maxValue: 2000,
    range: 100,
    symbolTemplate: `\${0}`,
    fixedAmount: 100,
  },
  'Google My Business Optimization': {
    planSelection: true,
    radioButtonOptions: yesNoRadioButtons,
  },
  'Marketing Center Plus': {
    planSelection: true,
    radioButtonOptions: yesNoRadioButtons,
  },
  'Marketing Center Pro': {
    planSelection: true,
    radioButtonOptions: yesNoRadioButtons,
  },
  'Marketing Center Pro Enhanced': {
    planSelection: true,
    radioButtonOptions: yesNoRadioButtons,
  },
  'Starter Growth Package': {
    planSelection: true,
    radioButtonOptions: yesNoRadioButtons,
  },
  'Expanded Growth Package': {
    planSelection: true,
    radioButtonOptions: yesNoRadioButtons,
  },
  Website: {planSelection: true, radioButtonOptions: yesNoRadioButtons},
  HIPAA: {planSelection: true, radioButtonOptions: yesNoRadioButtons},
}

const MC_ROLES = [
  'PremisePilot',
  'LeadConverter',
  'Admin',
  'InsideDemo',
  'PremiseDemo',
  'Premise',
  'VivialDemo',
  'Australia',
  'AUSalesusers',
  'New Zealand',
]

const MC_CODES = [
  'addon-4-mc-semi',
  'addon-4-mc-m2m',
  'addon-4-mcpro-m2m',
  'addon-4-mcproenh-m2m',
]
const MC_NEW_ORDER_ROLES = ['premisepilot', 'leadconverter', 'admin', 'premise']

const MC_UPGRADE_ORDER_ROLES = [
  'premise',
  'premisedemo',
  'insidedemo',
  'vivialdemo',
  'australia',
  'ausalesusers',
  'new zealand',
  'tss',
  'premisepilot',
  'leadconverter',
  'admin',
]

function AddOns({
  data,
  addProduct,
  removeProduct,
  updateProduct,
  currency,
  selectedCountry,
  listOfProducts,
  totalGmailSeats,
  availableGmailSeats,
  updateAvailableGmailSeats,
  currentAddOns,
  selectedPlan,
  mcDomainConfig,
  setMcDomainConfig,
  selectedIndustry,
  hasCSN = false,
  setShouldIncludeCSNAddon,
  currentGmailItems,
  isUpgrade = false,
  seoDomainConfig,
  setSeoDomainConfig,
  cohortEaid,
  isEaidFound,
  setAllowInvoice,
  setCohortEaid,
  setIsEaidFound,
  setIsLegacyUpgradeAllowed,
  thryvId,
  setIsCohort,
  isLegacySEO,
  accountInfo,
  accountSubscriptionData,
  listOfAddons,
}) {
  const [alphaAmount, setAlphaAmount] = useState(null)
  const [alphaDomain, setAlphaDomain] = useState(null)
  const [isExternalDomain, setIsExternalDomain] = useState(null)
  const [alphaProposalId, setAlphaProposalId] = useState(null)
  const [alphaKeyCount, setAlphaKeyCount] = useState(null)
  const [alphaPowerboostCount, setAlphaPowerboostCount] = useState(null)
  const disableValidateAndProposalInput = false
  const [isKeywordAdded, setKeywordAddStatus] = useState(false)
  const [isPowerboostAdded, setPowerboostAddStatus] = useState(false)
  const [wasItemInCart, setWasItemInCart] = useState({
    thryvLeads: false,
    seo: false,
  })
  const {pathname} = useLocation()
  const {roles} = useUserRoles()
  const {frontendMcTlOrders, frontendMcBoostedListings} = useFlags()

  let newSeoDetail = currentAddOns.find(
    item => item.addOnCode === 'addon-4-seobase-m2m',
  )
  let seoKeywordDetail = currentAddOns.find(
    item => item.addOnCode === 'addon-4-seokey-m2m',
  )
  let seoPowerBoostDetail = currentAddOns.find(
    item => item.addOnCode === 'addon-4-seopb-m2m',
  )

  const isTSS = roles.includes('TSS')
  const {billingPreference} = listOfProducts.find(
    product => product.type === 'selectedPlan' && product.billingPreference,
  )

  const isMarketingCenterPlan =
    listOfProducts.some(
      product =>
        product.type === 'selectedPlan' &&
        ADD_ON.add_ons['Marketing Center Plus'].addOnCodes.includes(
          product.planCode,
        ),
    ) &&
    (roles.includes('PremisePilot') ||
      roles.includes('LeadConverter') ||
      roles.includes('Admin'))
  const isUpgradeFlow = pathname === '/order/upgrade'
  const isMCRole = MC_ROLES.some(role => roles.includes(role))
  const isMCAvailableForTSS =
    isTSS && currentAddOns?.some(({addOnCode}) => MC_CODES.includes(addOnCode))

  const newSeoPlanDetails = {}
  data.map(item => {
    if (
      [
        'addon-4-seobase-m2m',
        'addon-4-seopb-m2m',
        'addon-4-seokey-m2m',
      ].includes(item['monthly'][0].code)
    ) {
      newSeoPlanDetails[`${item['monthly'][0].code}`] = {
        name: item.name,
        ...item['monthly'][0],
      }
    }
    return item
  })
  useEffect(() => {
    if (newSeoDetail) {
      setAlphaAmount(newSeoDetail.subtotal)
      setAlphaProposalId(newSeoDetail.proposalId)
      setAlphaDomain(newSeoDetail.domain)
    }
    if (seoKeywordDetail) {
      setAlphaKeyCount(seoKeywordDetail.quantity)
    }
    if (seoPowerBoostDetail) {
      setAlphaPowerboostCount(seoPowerBoostDetail.quantity)
    }
  }, [])
  useEffect(() => {
    if (alphaKeyCount !== null) {
      if (seoKeywordDetail) {
        let product = {
          id: `already-included-${seoKeywordDetail.name}`,
          product: seoKeywordDetail.name,
          type: 'addon',
          price: `$${
            (seoKeywordDetail.subtotal / seoKeywordDetail.quantity) *
            alphaKeyCount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }`,
          quantity: alphaKeyCount || seoKeywordDetail.quantity,
          billingPreference: billingPreference,
          planCode: seoKeywordDetail.addOnCode,
        }
        if (alphaKeyCount > seoKeywordDetail.quantity) {
          updateProduct(product, 'product')
        }
      } else {
        let seoKeywordPlan = newSeoPlanDetails[`addon-4-seokey-m2m`]
        if (seoKeywordPlan && Object.keys(seoKeywordPlan).length > 0) {
          let product = {
            id: seoKeywordPlan.id,
            product: seoKeywordPlan.name,
            type: 'addon',
            price: `$${
              seoKeywordPlan.currencies[0].unitAmount *
              alphaKeyCount
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }`,
            quantity: alphaKeyCount || 1,
            billingPreference: billingPreference,
            planCode: seoKeywordPlan.code,
          }
          if (alphaKeyCount !== 0) {
            if (isKeywordAdded) {
              updateProduct(product, 'product')
            } else {
              addProduct([product])
              setKeywordAddStatus(true)
            }
          } else {
            if (isKeywordAdded) {
              removeProduct(seoKeywordPlan.id)
              setKeywordAddStatus(false)
            }
          }
        }
      }
    }
  }, [alphaKeyCount])

  useEffect(() => {
    if (alphaPowerboostCount !== null) {
      if (seoPowerBoostDetail) {
        let product = {
          id: `already-included-${seoPowerBoostDetail.name}`,
          product: seoPowerBoostDetail.name,
          type: 'addon',
          price: `$${
            (seoPowerBoostDetail.subtotal / seoPowerBoostDetail.quantity) *
            alphaPowerboostCount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }`,
          quantity: alphaPowerboostCount || seoPowerBoostDetail.quantity,
          billingPreference: billingPreference,
          planCode: seoPowerBoostDetail.addOnCode,
        }
        if (alphaPowerboostCount > seoPowerBoostDetail.quantity) {
          updateProduct(product, 'product')
        }
      } else {
        let seoPowerboostPlan = newSeoPlanDetails[`addon-4-seopb-m2m`]
        if (seoPowerboostPlan && Object.keys(seoPowerboostPlan).length > 0) {
          let product = {
            id: seoPowerboostPlan.id,
            product: seoPowerboostPlan.name,
            type: 'addon',
            price: `$${
              seoPowerboostPlan.currencies[0].unitAmount *
              alphaPowerboostCount
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }`,
            billingPreference: billingPreference,
            quantity: alphaPowerboostCount || 1,
            planCode: seoPowerboostPlan.code,
          }
          if (alphaPowerboostCount !== 0) {
            if (isPowerboostAdded) {
              updateProduct(product, 'product')
            } else {
              addProduct([product])
              setPowerboostAddStatus(true)
            }
          } else {
            if (isKeywordAdded) {
              removeProduct(seoPowerboostPlan.id)
              setPowerboostAddStatus(false)
            }
          }
        }
      }
    }
  }, [alphaPowerboostCount])

  useEffect(() => {
    if (alphaProposalId && alphaAmount) {
      let seoBasePlan = newSeoPlanDetails[`addon-4-seobase-m2m`]
      if (seoBasePlan && Object.keys(seoBasePlan).length > 0) {
        let product = {
          id: seoBasePlan.id,
          product: seoBasePlan.name,
          type: 'addon',
          price: `$${alphaAmount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
          quantity: alphaAmount / seoBasePlan.currencies[0].unitAmount || 1,
          domain: alphaDomain,
          proposalId: alphaProposalId,
          billingPreference: billingPreference,
          planCode: seoBasePlan.code,
        }
        if (
          listOfProducts.find(item => item.planCode === 'addon-4-seobase-m2m')
        ) {
          updateProduct(product, 'product')
        } else {
          addProduct([product])
        }
      }
    }
  }, [alphaProposalId, alphaAmount, alphaDomain])

  const getTlInCart = ({product, id}) => {
    if (isUpgradeFlow && id?.includes('already-included')) {
      return product === 'Thryv Leads'
    }
    return product === 'Thryv Leads'
  }
  const isTLInCart = listOfProducts.some(({product, id}) =>
    getTlInCart({product, id}),
  )

  function checkIfAddonIsInCart(itemName, type) {
    const itemAddonCodes = ADD_ON.add_ons[itemName].addOnCodes
    const itemType = ADDONS_TYPES[type]
    return (
      listOfProducts.some(({planCode, type}) => {
        if (itemType)
          return itemAddonCodes.includes(planCode) && type === itemType
        return itemAddonCodes.includes(planCode)
      }) ||
      currentAddOns?.some(({addOnCode}) => itemAddonCodes.includes(addOnCode))
    )
  }

  function validateMCRoles() {
    let validRoleIsPresent = false

    let formattedRoles = roles.map(role =>
      role.toLowerCase().replace(/\s/g, ''),
    )
    // New Order -- where none of the MCs are present
    if (!isMCInCart && !isMCProInCart && !isMCProEnhancedInCart) {
      validRoleIsPresent = MC_NEW_ORDER_ROLES.some(role =>
        formattedRoles.includes(role),
      )
      return validRoleIsPresent
    }
    // Upgrade from MC Plus
    if (isMCInCart && !isMCProInCart && !isMCProEnhancedInCart) {
      validRoleIsPresent = MC_UPGRADE_ORDER_ROLES.some(role =>
        formattedRoles.includes(role),
      )
      return validRoleIsPresent
    }
    // Any other scenario - follow the existing user criteria logic
    if (isMCRole || isMCAvailableForTSS) {
      validRoleIsPresent = true
      return validRoleIsPresent
    }
    return validRoleIsPresent
  }

  const isMCInCart = checkIfAddonIsInCart('Marketing Center Plus', 'ADD_ON')
  const isMCProInCart = checkIfAddonIsInCart('Marketing Center Pro')
  const isMCProEnhancedInCart = checkIfAddonIsInCart(
    'Marketing Center Pro Enhanced',
  )
  const isSEOInCart = listOfProducts.some(({product}) =>
    product?.includes('SEO'),
  )

  const isOldSEO = listOfProducts.find(
    ({product, id}) =>
      product === 'SEO Money Back Guarantee' ||
      product === 'SEO Non-Guaranteed',
  )
  const isSEOAlreadyInCart = listOfProducts.find(
    ({product, id}) =>
      product?.includes('SEO') && id.includes('already-included'),
  )
  const isWebsiteInCart = listOfProducts.some(
    ({product}) => product === 'Website',
  )
  const isWebsiteIncluded = currentAddOns?.some(({name}) => name === 'Website')
  const noAddlVidViews = ['aus', 'cayman', 'barbados']
  const hasGMB = currentAddOns?.some(
    ({name}) =>
      name === 'GMB Add-on Monthly' ||
      name === 'GMB Add-on Semi Annually' ||
      name === 'Google My Business Optimization',
  )

  const getItemCode = (item, addOnCode) => {
    return !addOnCode.includes('-m2m') && item?.semiAnnually
      ? item?.semiAnnually[0]?.code
      : item?.monthly[0]?.code
  }

  const setItem = item => {
    const isAddOnSelected = currentAddOns?.some(
      ({addOnCode, name: addOnName}) => {
        const itemCode = getItemCode(item, addOnCode)
        return addOnCode === itemCode || addOnName === item.name
      },
    )
    const isMCPlus = item.name === ADD_ON.add_ons['Marketing Center Plus'].label
    const isMCPro = item.name === ADD_ON.add_ons['Marketing Center Pro'].label
    const isMCProEnhanced =
      item.name === ADD_ON.add_ons['Marketing Center Pro Enhanced'].label
    const isDisabled = isAddOnSelected
    const selectedAddOn = currentAddOns?.find(({addOnCode}) => {
      const itemCode = getItemCode(item, addOnCode)
      return addOnCode === itemCode
    })
    const itemConfig = ITEMS_CONFIG[item.name]
    const selectedItemConfig = {
      ...itemConfig,
      radioButtonOptions: defaultYesRadioButtons,
    }

    if (isMCProEnhanced) {
      if (!isMCProEnhancedInCart) {
        return
      }
    }

    if ((isMCPlus || isMCPro) && isMCProEnhancedInCart) return

    if (
      item.name === ADD_ON.add_ons['Marketing Center Pro Enhanced'].label &&
      (isMCInCart || isMCProInCart)
    )
      return

    if (
      item.name === ADD_ON.add_ons['Website'].label &&
      (isMCProInCart || isMCProEnhancedInCart)
    )
      return

    if (item.name === ADD_ON.add_ons['Additional Social Boosting'].label) {
      return
    }

    if (
      item.name === ADD_ON.add_ons['Additional Social Boosting'].label ||
      item.name === ADD_ON.add_ons['SEO Money Back Guarantee'].label ||
      (item.name === ADD_ON.add_ons['Additional Video Views'].label &&
        noAddlVidViews.includes(selectedCountry))
    )
      return

    if (
      item.name === ADD_ON.add_ons['Google My Business Optimization'].label &&
      !hasGMB
    )
      return

    if (
      item.name === ADD_ON.add_ons['Starter Growth Package'].label ||
      item.name === ADD_ON.add_ons['Expanded Growth Package'].label
    ) {
      if (!isMCInCart && !isMCProInCart) {
        return
      } else {
        if (!frontendMcBoostedListings) {
          return
        }
        return (
          <MonthSemiAnnualSelection
            item={item}
            key={item.name}
            currency={currency}
            addProduct={addProduct}
            removeProduct={removeProduct}
            updateProduct={updateProduct}
            itemConfig={
              isMarketingCenterPlan
                ? ITEMS_CONFIG[item.name]
                : isAddOnSelected
                ? selectedItemConfig
                : itemConfig
            }
            listOfProducts={listOfProducts}
            billingPreference={billingPreference}
            availableGmailSeats={availableGmailSeats}
            totalGmailSeats={totalGmailSeats}
            currentGmailItems={currentGmailItems}
            handleUpdateAvailableGmailSeats={updateAvailableGmailSeats}
            isAddOnSelected={isAddOnSelected}
            selectedAddOn={selectedAddOn}
            isDisabled={isDisabled}
            isUpgrade={isUpgrade}
            currentAddOns={currentAddOns}
            isTSS={isTSS}
          />
        )
      }
    }
    if (item.name === ADD_ON.add_ons['SEO Non-Guaranteed'].label) {
      if ((isWebsiteInCart || isWebsiteIncluded || isLegacySEO) && isOldSEO) {
        const seoMbgItem = data.find(
          addon =>
            addon.name === ADD_ON.add_ons['SEO Money Back Guarantee'].label,
        )
        let isSeoMbgItemDefault = SEO_BOTH_PLANS.includes(selectedPlan?.name)

        if (isSeoMbgItemDefault && isLegacySEO) {
          if (!(isWebsiteInCart || isWebsiteIncluded)) {
            isSeoMbgItemDefault = false
          }
        }
        const mainSeoItemInfo =
          isSeoMbgItemDefault && seoMbgItem ? seoMbgItem : item
        return (
          <AddOnMc
            mcIsOnCart={isSEOInCart}
            mcDomainConfig={seoDomainConfig}
            setMcDomainConfig={setSeoDomainConfig}
            key={mainSeoItemInfo.name}
            setAllowInvoice={setAllowInvoice}
            setIsCohort={setIsCohort}
            setCohortEaid={setCohortEaid}
            setIsEaidFound={setIsEaidFound}
            thryvId={thryvId}
          >
            <ThryvValidatedAddons
              item={mainSeoItemInfo}
              title={mainSeoItemInfo.name}
              addProduct={addProduct}
              removeProduct={removeProduct}
              updateProduct={updateProduct}
              selectedCountry={selectedCountry.toUpperCase()}
              radioButtonOptions={itemConfig.radioButtonOptions}
              listOfProducts={listOfProducts}
              billingPreference={billingPreference}
              currentAddOns={currentAddOns}
              isTLInCart={isTLInCart}
              isSEOInCart={isSEOAlreadyInCart}
              seoMbgItem={isSeoMbgItemDefault ? item : seoMbgItem}
              isSeoMbgItemDefault={isSeoMbgItemDefault}
              addBorderBottom={false}
              isDisabled={listOfProducts.some(item =>
                [
                  'addon-4-seobase-m2m',
                  'addon-4-seopb-m2m',
                  'addon-4-seokey-m2m',
                ].includes(item.planCode),
              )}
            />
          </AddOnMc>
        )
      }
      return
    }
    if (item.name.trim() === 'SEO') {
      if (
        selectedCountry === 'usa' &&
        (isMCInCart || isMCProInCart || isMCProEnhancedInCart)
      ) {
        return (
          <>
            <SEOSummary
              alphaProposalId={alphaProposalId}
              setAlphaProposalId={setAlphaProposalId}
              alphaDomain={alphaDomain}
              setAlphaDomain={setAlphaDomain}
              alphaAmount={alphaAmount}
              setAlphaAmount={setAlphaAmount}
              alphaKeyCount={alphaKeyCount}
              setAlphaKeyCount={setAlphaKeyCount}
              alphaPowerboostCount={alphaPowerboostCount}
              setAlphaPowerboostCount={setAlphaPowerboostCount}
              diableValidateAndProposalInput={disableValidateAndProposalInput}
              isExternalDomain={isExternalDomain}
              setIsExternalDomain={setIsExternalDomain}
              listOfProducts={listOfProducts}
              removeProduct={removeProduct}
              addProduct={addProduct}
            />
          </>
        )
      }
      return
    }

    if (item.name === 'SEO Keyword' || item.name === 'SEO PowerBoost') {
      return <></>
    }
    if (item.name === 'Thryv Leads') {
      if (selectedCountry === 'usa') {
        return (
          <ThryvValidatedAddons
            item={item}
            key={item.name}
            title={item.name}
            addProduct={addProduct}
            removeProduct={removeProduct}
            updateProduct={updateProduct}
            selectedCountry={selectedCountry.toUpperCase()}
            radioButtonOptions={itemConfig.radioButtonOptions}
            listOfProducts={listOfProducts}
            billingPreference={billingPreference}
            currentAddOns={currentAddOns}
            isTLInCart={isTLInCart}
            isSEOInCart={isSEOAlreadyInCart}
            isCSNInCart={hasCSN}
            isDisabled={
              !frontendMcTlOrders
                ? isMCInCart || isMCProInCart || isMCProEnhancedInCart
                : false
            }
            setShouldIncludeCSNAddon={setShouldIncludeCSNAddon}
          />
        )
      }
      return
    }
    if (item.name === ADD_ON.add_ons['Enhanced Accessibility'].label) {
      if (isWebsiteInCart) {
        return (
          <MonthSemiAnnualSelection
            item={item}
            key={item.name}
            currency={currency}
            addProduct={addProduct}
            removeProduct={removeProduct}
            updateProduct={updateProduct}
            itemConfig={
              isMarketingCenterPlan
                ? MCPlanConfig
                : isAddOnSelected
                ? selectedItemConfig
                : itemConfig
            }
            listOfProducts={listOfProducts}
            billingPreference={billingPreference}
            availableGmailSeats={availableGmailSeats}
            totalGmailSeats={totalGmailSeats}
            handleUpdateAvailableGmailSeats={updateAvailableGmailSeats}
            isAddOnSelected={isAddOnSelected}
            selectedAddOn={selectedAddOn}
            isDisabled={isDisabled}
            currentAddOns={currentAddOns}
          />
        )
      }
      return
    }

    if (item.name === 'Website') {
      return (
        <MonthSemiAnnualSelection
          item={item}
          key={item.name}
          currency={currency}
          addProduct={addProduct}
          removeProduct={removeProduct}
          updateProduct={updateProduct}
          itemConfig={
            isMarketingCenterPlan
              ? MCPlanConfig
              : isAddOnSelected
              ? selectedItemConfig
              : itemConfig
          }
          listOfProducts={listOfProducts}
          billingPreference={billingPreference}
          availableGmailSeats={availableGmailSeats}
          totalGmailSeats={totalGmailSeats}
          handleUpdateAvailableGmailSeats={updateAvailableGmailSeats}
          isAddOnSelected={isAddOnSelected}
          selectedAddOn={selectedAddOn}
          isDisabled={isDisabled}
          currentAddOns={currentAddOns}
        />
      )
    }

    if (
      [
        ADD_ON.add_ons['Marketing Center Plus'].label,
        ADD_ON.add_ons['Marketing Center Pro'].label,
        ADD_ON.add_ons['Marketing Center Pro Enhanced'].label,
      ].includes(item.name)
    ) {
      // MC Plus and Pro
      if (
        ['usa', 'aus', 'can'].includes(selectedCountry) &&
        selectedIndustry !== 'sync_leads' &&
        [
          ADD_ON.add_ons['Marketing Center Plus'].label,
          ADD_ON.add_ons['Marketing Center Pro'].label,
        ].includes(item.name) &&
        validateMCRoles()
      ) {
        const isMCProEnhanced =
          item.name === ADD_ON.add_ons['Marketing Center Pro Enhanced'].label

        return (
          <AddOnMc
            mcIsOnCart={
              isMCPlus
                ? isMCInCart
                : isMCPro
                ? isMCProInCart
                : isMCProEnhancedInCart
            }
            mcDomainConfig={mcDomainConfig}
            setMcDomainConfig={setMcDomainConfig}
            key={item.name}
            isMCInCart={isMCInCart}
            isMCProInCart={isMCProInCart}
            isMCProEnhancedInCart={isMCProEnhancedInCart}
            isMCProEnhanced={isMCProEnhanced}
            cohortEaid={cohortEaid}
            setCohortEaid={setCohortEaid}
            isEaidFound={isEaidFound}
            setAllowInvoice={setAllowInvoice}
            setIsCohort={setIsCohort}
            setIsEaidFound={setIsEaidFound}
            setIsLegacyUpgradeAllowed={setIsLegacyUpgradeAllowed}
            thryvId={thryvId}
          >
            <MonthSemiAnnualSelection
              item={item}
              key={item.name}
              currency={currency}
              addProduct={addProduct}
              removeProduct={removeProduct}
              updateProduct={updateProduct}
              itemConfig={isAddOnSelected ? selectedItemConfig : itemConfig}
              listOfProducts={listOfProducts}
              billingPreference={billingPreference}
              availableGmailSeats={availableGmailSeats}
              totalGmailSeats={totalGmailSeats}
              handleUpdateAvailableGmailSeats={updateAvailableGmailSeats}
              isAddOnSelected={isAddOnSelected}
              selectedAddOn={selectedAddOn}
              isDisabled={
                frontendMcTlOrders ? isDisabled : isDisabled || isTLInCart
              }
              addBorderBottom={false}
              selectedPlanCode={selectedPlan.planCode}
              currentAddOns={currentAddOns}
            />
          </AddOnMc>
        )
      }
      if (
        ['usa', 'aus', 'can'].includes(selectedCountry) &&
        (isMCRole || isMCAvailableForTSS) &&
        selectedIndustry !== 'sync_leads' &&
        [ADD_ON.add_ons['Marketing Center Pro Enhanced'].label].includes(
          item.name,
        )
      ) {
        const isMCProEnhanced =
          item.name === ADD_ON.add_ons['Marketing Center Pro Enhanced'].label

        return (
          <AddOnMc
            mcIsOnCart={
              isMCPlus
                ? isMCInCart
                : isMCPro
                ? isMCProInCart
                : isMCProEnhancedInCart
            }
            mcDomainConfig={mcDomainConfig}
            setMcDomainConfig={setMcDomainConfig}
            key={item.name}
            isMCInCart={isMCInCart}
            isMCProInCart={isMCProInCart}
            isMCProEnhancedInCart={isMCProEnhancedInCart}
            isMCProEnhanced={isMCProEnhanced}
            cohortEaid={cohortEaid}
            setCohortEaid={setCohortEaid}
            isEaidFound={isEaidFound}
            setAllowInvoice={setAllowInvoice}
            setIsCohort={setIsCohort}
            setIsEaidFound={setIsEaidFound}
            setIsLegacyUpgradeAllowed={setIsLegacyUpgradeAllowed}
            thryvId={thryvId}
          >
            <MonthSemiAnnualSelection
              item={item}
              key={item.name}
              currency={currency}
              addProduct={addProduct}
              removeProduct={removeProduct}
              updateProduct={updateProduct}
              itemConfig={isAddOnSelected ? selectedItemConfig : itemConfig}
              listOfProducts={listOfProducts}
              billingPreference={billingPreference}
              availableGmailSeats={availableGmailSeats}
              totalGmailSeats={totalGmailSeats}
              handleUpdateAvailableGmailSeats={updateAvailableGmailSeats}
              isAddOnSelected={isAddOnSelected}
              selectedAddOn={selectedAddOn}
              isDisabled={
                frontendMcTlOrders ? isDisabled : isDisabled || isTLInCart
              }
              addBorderBottom={false}
              selectedPlanCode={selectedPlan.planCode}
              currentAddOns={currentAddOns}
            />
          </AddOnMc>
        )
      }
      return
    }
    return (
      <MonthSemiAnnualSelection
        item={item}
        key={item.name}
        currency={currency}
        addProduct={addProduct}
        removeProduct={removeProduct}
        updateProduct={updateProduct}
        itemConfig={
          isMarketingCenterPlan
            ? MCPlanConfig
            : isAddOnSelected
            ? selectedItemConfig
            : itemConfig
        }
        listOfProducts={listOfProducts}
        billingPreference={billingPreference}
        availableGmailSeats={availableGmailSeats}
        totalGmailSeats={totalGmailSeats}
        currentGmailItems={currentGmailItems}
        handleUpdateAvailableGmailSeats={updateAvailableGmailSeats}
        isAddOnSelected={isAddOnSelected}
        selectedAddOn={selectedAddOn}
        isDisabled={isDisabled}
        isUpgrade={isUpgrade}
        currentAddOns={currentAddOns}
        isTSS={isTSS}
      />
    )
  }

  const formatProduct = item => {
    const {quantity, name, subtotal, addOnCode} = item
    return {
      id: `already-included-${name}`,
      product: name,
      type: ADDONS_TYPES['ADD_ON'],
      price: `$${(subtotal || 0)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      quantity: quantity,
      billingPreference,
      planCode: addOnCode,
    }
  }

  useEffect(() => {
    const itemsToAdd = []
    currentAddOns?.forEach(addon => {
      const isTLorSEO =
        addon.name.includes('SEO') || addon.name === 'Thryv Leads'
      const isItemInCart = listOfProducts.some(
        ({product}) => product === addon.name,
      )
      const key = addon.name === 'Thryv Leads' ? 'thryvLeads' : 'seo'

      if (isTLorSEO && !isItemInCart && !wasItemInCart[key]) {
        itemsToAdd.push(formatProduct(addon))
      }
    })

    if (itemsToAdd.length) {
      const newWasItemInCart = {...wasItemInCart}
      itemsToAdd.forEach(({product}) => {
        if (product === 'Thryv Leads') newWasItemInCart.thryvLeads = true
        else newWasItemInCart.seo = true
      })
      addProduct(itemsToAdd)
      setWasItemInCart(newWasItemInCart)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfProducts])

  return (
    <SelectionAdds>
      <Header fontWeight="bold" variant="h5" className="ml-3">
        Add-Ons
      </Header>
      <ItemContainer>
        {data?.map((item, index) => (
          <div key={index}>{setItem(item)}</div>
        ))}
      </ItemContainer>
    </SelectionAdds>
  )
}

export {AddOns}
